import * as z from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Checkbox } from "@/components/ui/checkbox"
import { Button } from "@/components/ui/button"
import { useNav } from "@/app/features/profile/NavContext"
import { useEffect, useState } from "react"
import { useCreateElementGroupMutation, useGetElementGroupQuery, useUpdateElementGroupMutation } from "@app.raytd.com/store"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import { useCallback } from 'react';
import { useUnsavedChanges } from "@/hooks/useUnsavedChanges"
import BusyButton from "@/components/raytd/busy-button"

const formSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().min(1, "Description is required"),
    test_suite_types: z.array(z.string()).refine((value) => value.length > 0, {
        message: "You must select at least one permitted use",
    }),
})

const permittedUseOptions = [
    { id: "compliance", label: "Compliance Test Suites" },
    { id: "condition", label: "Condition Assessment Test Suites" },
    { id: "generic", label: "General Test Suites" },
    { id: "custom", label: "Custom Result Test Suites" },
]

export const ElementGroupForm = ({ element }) => {

    const navigate = useNavigate();

    const [formDisabled, setFormDisabled] = useState(false);

    const [updateElementGroup, { isLoading: isUpdatingElementGroup }] = useUpdateElementGroupMutation();
    const [createElementGroup, { isLoading: isCreatingElementGroup }] = useCreateElementGroupMutation();

    const isBusy = isUpdatingElementGroup || isCreatingElementGroup;

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: "",
            description: "",
            test_suite_types: [],
        },
        disabled: formDisabled,
    });

    const { formState } = form;
    const { isDirty } = formState;

    const { AlertDialogComponent } = useUnsavedChanges(isDirty, form.reset, formDisabled);

    const onSubmit = useCallback(async (values: z.infer<typeof formSchema>) => {
        console.debug('save element group', values, element);
        try {
            if (element?.id !== null) {
                await updateElementGroup({ id: element.id, ...values }).unwrap();
            } else {
                const reponse = await createElementGroup(values).unwrap();

                setFormDisabled(true);

                setTimeout(() => {
                    navigate(`/elements/${reponse.id}`);
                }, 10);
            }

            toast.success("Element group saved successfully.");
        } catch (error) {
            console.error(error);
            toast.error("An error occurred. Please try again.");
        }
    }, [element, updateElementGroup]);

    const { setNavButtons } = useNav();

    useEffect(() => {
        setNavButtons([
                <BusyButton
                onClick={form.handleSubmit(onSubmit)}
                disabled={!isDirty}
                isBusy={isBusy}
                busyText="Saving..."
            >
                Save
            </BusyButton>

        ])

        return () => {
            setNavButtons([])
        }
    }, [setNavButtons, element, form.handleSubmit, onSubmit, isDirty, isBusy]);

    useEffect(() => {
        if (element) {
            form.reset(element);

            const isAdmin = true; // Implement role logic here
            setFormDisabled(!isAdmin);
    
        }
    }, [element, form.reset])

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Name*</FormLabel>
                            <FormControl>
                                <Input placeholder="Element Group Name..." {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Description*</FormLabel>
                            <FormControl>
                                <Textarea
                                    placeholder="Element Group Description..."
                                    className="resize-none"
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="test_suite_types"
                    render={() => (
                        <FormItem>
                            <div className="mb-4">
                                <FormLabel className="text-base">Permitted Use</FormLabel>
                                <FormDescription>
                                    Select the permitted uses for this element group.
                                </FormDescription>
                            </div>
                            <div className="bg-zinc-100 space-y-2 p-6">
                                {permittedUseOptions.map((option) => (
                                    <FormField
                                        key={option.id}
                                        control={form.control}
                                        name="test_suite_types"
                                        render={({ field }) => {
                                            return (
                                                <FormItem
                                                    key={option.id}
                                                    className="flex flex-row items-start space-x-3 space-y-0"
                                                >
                                                    <FormControl>
                                                        <Checkbox
                                                            checked={field.value?.includes(option.id)}
                                                            onCheckedChange={(checked) => {
                                                                return checked
                                                                    ? field.onChange([...field.value, option.id])
                                                                    : field.onChange(
                                                                        field.value?.filter(
                                                                            (value) => value !== option.id
                                                                        )
                                                                    )
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormLabel className="font-normal">
                                                        {option.label}
                                                    </FormLabel>
                                                </FormItem>
                                            )
                                        }}
                                    />
                                ))}
                            </div>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </form>
            <AlertDialogComponent />
        </Form>
    )
}


const Details = () => {

    const { elementId } = useParams<{ elementId: string }>();
    const { data: element, isLoading } = useGetElementGroupQuery(elementId);

    return (
        <div className="max-w-xl">
            <ElementGroupForm element={element} />
        </div>
    )
}

export default Details;