import { worker } from './browser';

declare const process: any;
const isDevelopment = process.env.NODE_ENV === 'development';

console.info('isDevelopment', isDevelopment);
isDevelopment && console.info('env', process.env);

if (typeof window !== 'undefined' && isDevelopment) {
  //  worker.start();
}