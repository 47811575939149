import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { Toaster } from 'sonner';
import { BreadcrumbProvider } from '@/app/layout2/BreadcrumbContext';

export const MainPage: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 border-t border-l rounded-tl-2xl border-r rounded-tr-2xl bg-white">
      {children}
    </main>
  )
}

export const MainPagewithColumns: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className="flex-1 grid grid-cols-2 gap-4 lg:gap-6">
      {children}
    </main>
  )
}

const AppLayout: React.FC = () => {
  return (
    <BreadcrumbProvider>
    <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]  bg-muted/50">
      <Sidebar />
      <div className="flex flex-col space-y-4">
        
        <Header />
        <Toaster />
        <Outlet />
      </div>
    </div>
    </BreadcrumbProvider>
  );
};

export default AppLayout;
