import { NavProvider } from "@/app/features/profile/NavContext";
import { PropsWithChildren } from "react";

const PageWithTopBarNavigation: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <NavProvider>
            <div className="min-h-screen">
                {children}
            </div>
        </NavProvider>
    );

}

export default PageWithTopBarNavigation;