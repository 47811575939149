import { AssetForm } from "@/app/features/assets/AssetDetails";
import { ElementGroupForm } from "@/app/features/elements/Details";
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { TopNavBar } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { SquareChevronLeft } from "lucide-react";
import { NavLink } from "react-router-dom";

const NewElementGroupPage = () => {

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="/elements"><SquareChevronLeft className="h-6 w-6 text-zinc-500" /></NavLink>
                </TopNavBar>
                <div className="mb-3" />
                <SubPageLayout title='Create Element Group'>
                    <div className="max-w-xl">

                        <ElementGroupForm
                            // title='Create Element Group'
                            element={{
                                id: null,
                                name: '',
                                description: '',
                                status: 'active',
                            }} />
                    </div>
                </SubPageLayout>

            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default NewElementGroupPage;