
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  LoginPage
} from '@app.raytd.com/ui';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';


// eslint-disable-next-line
import '../../store/src/lib/mocks';

import { PrivateRoute } from './_components';
import { Logout } from './layout/logout';

import AssetPage from '@/app/features/assets/AssetPage';
import AssetView from '@/app/features/assets/AssetView';
import ForgotPasswordPage from '@/app/features/auth/ForgotPasswordPage';
import ResetPasswordPage from '@/app/features/auth/ResetPasswordPage';
import SignupPage from '@/app/features/auth/SignupPage';
import { OrganisationPage } from '@/app/features/organisation/OrganisationPage';
import ProfilePage from '@/app/features/profile/ProfilePage';
import TestSuiteList from '@/app/features/testsuites/testSuiteList';
import AppLayout from '@/app/layout2/AppLayout';
import AuthLayout from '../../ui/src/lib/login/AuthLayout';
import DashboardPage from './features/dashboard/DashboardPage';
import AcceptInvite from './features/userOnboarding/components/acceptInvite';
import AdminLayout from '@/app/features/admin/AdminLayout';
import UsersAdminPage from '@/app/features/admin/UsersAdminPage';
import NewAssetPage from '@/app/features/assets/NewAssetPage';
import ElementGroups from '@/app/features/elements/ElementGroupPage';
import ElementPageLayout from '@/app/features/elements/ElementPageLayout';
import NewElementGroupPage from '@/app/features/elements/NewElementGroupPage';

const ErrorThrowingComponent: React.FC = () => {
  throw new Error('This is a simulated error');
};

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: '/invite/:token',
        element: <AcceptInvite />
      }
    ]
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: '/invite/:token',
            element: <AcceptInvite />
          }
        ]
      },
      {
        path: '/',
        element: <AppLayout />,
        children: [
          {
            path: '/',
            element: <DashboardPage />
          },
          {
            path: 'test-suites',
            element: <TestSuiteList />,
          },
          {
            path: 'logout',
            element: <Logout />
          },
          {
            path: 'profile/*',
            element: <ProfilePage />
          },
          {
            path: 'organisation/*',
            element: <OrganisationPage />
          },
          {
            path: 'assets/*',
            element: <AssetPage />
          },
          {
            path: '/assets/new',
            element: <NewAssetPage />
          },
          {
            path: 'assets/:assetId/*',
            element: <AssetView />
          },
          {
            path: '/support',
            element: <ErrorThrowingComponent />
          },
          {
            path:'/elements',
            element: <ElementGroups />
          },
          {
            path: 'elements/:elementId/*',
            element: <ElementPageLayout />
          },
          {
            path: 'elements/new',
            element: <NewElementGroupPage />
          },
         
        ]

      },

      {
        element: <AdminLayout />,
        path: 'admin',
        children: [
          {
            path:'users',
            element: <UsersAdminPage />
          }
        ]
      },
      {
        path: '*'
      }
      

    ]
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: '/signup',
        element: <SignupPage />
      },
      {
        path: '/forgot-password',
        element: <ForgotPasswordPage />
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />
      }
    ]
  },
]);


export const App = () => {
  return <RouterProvider router={router} />;
}

export default App;
