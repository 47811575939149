import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    ColumnDef,
    ColumnMeta,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Archive, Copy, MoreHorizontal } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@/components/ui/table'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import DataTablePagination from '@/app/features/assets/TablePagination'
import { MainPage } from '@/app/layout2/AppLayout'
import SubPageLayout from '@/app/features/organisation/SubPageLayout'
import Filters from '@/app/features/assets/Filters'
import { useTableStatusFilter } from '@/hooks/use-table-status-filter'
import { ClassificationTree, useGetElementGroupsQuery } from '@app.raytd.com/store'
import { SkeletonLoader } from '@/app/features/assets/Skeleton'
import OrganisationAvatar from '@/components/raytd/organisation-avatar'
import { organisationActions } from '../../../../store/src/lib/organisation/organisation.slice';
import EmptyState from '@/components/raytd/empty-state'

interface ExtendedColumnMeta extends ColumnMeta<ClassificationTree, unknown> {
    cellClassName?: string;
}

interface ElementGroup {
    id: string
    name: string
    description: string
    imageUrl: string
    status: 'active' | 'archived'
}

const elementGroups: ElementGroup[] = [
    { id: '1', name: 'Element Group Name', description: 'This is a description of the element group.', imageUrl: '/placeholder.svg?height=40&width=40', status: 'active' },
    { id: '2', name: 'Element Group Name', description: 'This is a description of the element group.', imageUrl: '/placeholder.svg?height=40&width=40', status: 'active' },
    { id: '3', name: 'Element Group Name', description: 'This is a description of the element group.', imageUrl: '/placeholder.svg?height=40&width=40', status: 'active' },
    { id: '4', name: 'Element Group Name', description: 'This is a description of the element group.', imageUrl: '/placeholder.svg?height=40&width=40', status: 'archived' },
    { id: '5', name: 'Element Group Name', description: 'This is a description of the element group.', imageUrl: '/placeholder.svg?height=40&width=40', status: 'active' },
]

export default function ElementGroups() {
    const navigate = useNavigate()

    const { data: elementGroups, isLoading, isError } = useGetElementGroupsQuery(6);

    const { filter, setFilter, statusFilter, setStatusFilter, statusColumnFilterFn, searchTerm, setSearchTerm, globalFilterFn } = useTableStatusFilter();

    const [columnFilters, setColumnFilters] = useState([]);

    const handleItemClick = (elementGroup) => {
        navigate(`/elements/${elementGroup.id}`);
    }

    useEffect(() => {
        console.info('Status filter changed:', statusFilter);
        setColumnFilters([{ id: 'status', value: statusFilter }]);
    }, [statusFilter]);

    const columns: ColumnDef<ClassificationTree>[] = [
        {
            accessorKey: 'avatar',
            size: 50,
            meta: {
                cellClassName: 'w-10',
            },
            cell: ({ row }) => (
                <OrganisationAvatar organisation={row.original.organisation} size='sm' />
            ),
        },
        {
            accessorKey: 'name',
            header: 'Element Groups',
            cell: ({ row }) => (
                <Button variant="ghost" onClick={() => handleItemClick(row.original)}>
                    <div className="flex items-center space-x-4">
                        <div className="font-semibold">{row.original.name}</div>
                    </div>
                </Button>),
        },
        {
            accessorKey: 'status',
            filterFn: statusColumnFilterFn,
        },
        {
            id: 'actions',
            meta: {
                cellClassName: 'w-10',
            },
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => console.log('Duplicate', row.original.id)}>
                            <Copy className="mr-2 h-4 w-4" />
                            Duplicate
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => console.log('Archive', row.original.id)}>
                            <Archive className="mr-2 h-4 w-4" />
                            Archive
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
        },
    ];

    //@ts-ignore
    const table = useReactTable({
        data: elementGroups,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnFilters,
            //@eslint-disable-next-line
            globalFilter: searchTerm,
        },
        onGlobalFilterChange: setSearchTerm,
        globalFilterFn: (row, columnId, filterValue) => {
            return row.original.name.toLowerCase().includes(filterValue.toLowerCase());
        },
        //globalFilterFn,
        initialState: {
            columnVisibility: {
                status: false,
            },
        },
    });

    const isFetching = false;

    const handleCreate = () => {
        navigate('/elements/new');
    }

    return (
        <>
            <div className="flex flex-row justify-end items-center space-x-4">
                <Filters selected={statusFilter} setFilter={setStatusFilter} setSearchTerm={setSearchTerm} />
                <Button className="" variant="default" onClick={handleCreate}>+ Create Element Group</Button>
            </div>
            <MainPage>
                <SubPageLayout
                    title="Element Groups"
                    subtitle='Create, update, manage and share your element groups.'
                    showSpinner={isFetching}
                >

                    <div className="space-y-4">

                        <div className="rounded-md border">
                            <Table>
                                <TableBody>
                                    <ElementGroupTableBody isLoading={isLoading} data={elementGroups} table={table} columns={columns} />
                                </TableBody>
                            </Table>
                        </div>
                        {!isLoading && (
                            <DataTablePagination table={table} itemsText='element groups' />
                        )}
                    </div>
                </SubPageLayout>
            </MainPage>
        </>
    )
}

const ElementGroupTableBody = ({ data, table, columns, isLoading }) => {

    if (isLoading) {
        return <SkeletonLoader />;
    }

    if (!data) {
        return null;
    }

    if (data.length === 0) {
        return <EmptyState
            title="You have no element groups."
            description="Create your first element group to get started."
        />;
    }

    if (table.getFilteredRowModel().rows?.length === 0) {
        return <NoSearchResults columnsLength={columns.length} text="No element groups found." />
    }

    const rows = table.getRowModel().rows;
    return <TableRows rows={rows} />;
};

const TableRows = ({ rows }) => (
    <>
        {rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}
                        className={(cell.column.columnDef.meta as ExtendedColumnMeta)?.cellClassName}
                        style={{
                            maxWidth: cell.column.columnDef.size,
                            alignContent: cell.column.columnDef.align,
                        }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                ))}
            </TableRow>
        ))}
    </>
);


const NoSearchResults = ({ columnsLength, text }) => (
    <TableRow>
        <TableCell colSpan={columnsLength} className="h-24 text-center">
            No element groups found.
        </TableCell>
    </TableRow>
);