import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Box, Button, Cell, ComposerHeader, CustomModalLayout, FloatingNotification, FormField, Input, InputArea, Layout, Modal, Stepper, CircularProgressBar } from "@wix/design-system";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TestSuiteSelection } from "../test/TestSuiteSelection";
import { TestSuite } from "store/src/lib/tests/entity";

interface TestSuiteEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    handleSave: (testSuite: TestSuite) => Promise<TestSuite>;
    savingStatus?: string;
    testSuite: TestSuite;
}

const ratingTypeOptions = [
    { id: 'single', label: 'Single', value: 'Single' },
    { id: 'multiple', label: 'Individualised', value: 'Individualised' },
    { id: 'no_rating', label: 'No Rating', value: 'No Rating' }
];

const RenderWaiter = ({ onComplete, status = 'saving', message }: { onComplete: () => void, status: 'saving' | 'error' | 'done', message: string }) => {

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const waitTime = Math.random() * (5000 - 3000) + 3000; // Random time between 3-5 seconds
        const interval = waitTime / 100; // Divide by 100 to get the interval for each 1% progress

        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (status === 'error') {
                    clearInterval(timer);
                    return oldProgress;
                }

                if (oldProgress === 100) {
                    clearInterval(timer);
                    onComplete();
                    return 100;
                }
                return Math.min(oldProgress + 1, 100);
            });
        }, interval);

        return () => {
            clearInterval(timer);
        };
    }, [status, onComplete]);

    const label = status === "saving" ? "Saving your test suite..." :
        status === 'error' ? "Error saving your test suite: " + message : "Preparing your test suite for customization...";

    return (
        <Box padding="medium" display="block" flex={1}>
            <Layout gap={'12px'}>
                <Cell span={12}>
                    <Box align="center" verticalAlign="middle">
                        <CircularProgressBar
                            size="large"
                            error={status === "error"}
                            errorMessage={message}
                            showProgressIndication={true}
                            value={progress}
                            label={label}
                            labelPlacement="bottom"
                        />
                    </Box>
                </Cell>
            </Layout>
        </Box>
    )
}

const TestSuiteEditModal = (props: TestSuiteEditModalProps) => {
    const { testSuite, isOpen, onClose, savingStatus, handleSave } = props;
    //    const { data, error, isLoading } = useTestSuite(testSuiteId);

    const { control, reset, handleSubmit, trigger, getValues, formState: { errors } } = useForm();
    const formRef = React.useRef<HTMLFormElement>(null);

    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [saveStatus, setSaveStatus] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [ready, setReady] = useState(false);

    const beforeOnSave = useCallback(() => {
        if (savingStatus === 'saving') return;
        formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }, [savingStatus]);

    const onSubmit = useCallback(async (data) => {
        try {
            setShowErrorMessage(false);
            setSaveStatus('');

            if (!handleSave) return;

            const response = await handleSave({ ...testSuite, ...data });
            reset(response);
            // Optionally navigate to test suite page;
        } catch (err) {
            setSaveStatus(err.message);
            setShowErrorMessage(true);
        }
    }, [handleSave, reset, testSuite]);

    useEffect(() => {
        reset(testSuite);
        setActiveStep(0);
        setShowErrorMessage(false);
        setSaveStatus('');
    }, [testSuite, reset]);

    const handleNextClick = useCallback(async () => {
        if (activeStep === 0) {
            const result = await trigger(['title', 'description']);
            if (result) setActiveStep(activeStep + 1);
        } else if (activeStep === 1) {
            const result = await trigger(['type']);
            if (result) {
                setActiveStep(activeStep + 1);
                beforeOnSave();
            }
        }
    }, [activeStep, trigger, beforeOnSave]);

    const handleBackClick = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const onProgressComplete = useCallback(() => {
        setReady(true);
    }, []);

    const renderStepOne = () => {
        return (
            <Box padding="medium" display="block" flex={1}>
                <Layout gap={'12px'}>
                    <Cell>
                        {showErrorMessage && (
                            <Box
                                position="absolute"
                                top="24px"
                                left="50%"
                                transform="translateX(-50%)"
                            >
                                <FloatingNotification
                                    prefixIcon={<ExclamationTriangleIcon />}
                                    type="destructive"
                                    text={saveStatus}
                                    onClose={() => setShowErrorMessage(false)}
                                />
                            </Box>
                        )}
                    </Cell>
                    <Cell>
                        <FormField
                            label="Name"
                            required
                            status={errors.title ? 'error' : null}
                            statusMessage={errors.title ? errors.title.message as string : null}
                            infoContent="The test suite name will be included in your reports and visible to report recipients"
                        >
                            <Controller
                                name="title"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Input
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        ref={ref}
                                        placeholder='Enter a test suite name'

                                    />
                                )}
                            />
                        </FormField>
                    </Cell>
                    <Cell>
                        <FormField
                            label="Description"
                            statusMessage={errors.description ? errors.description.message as string : null}
                            status={errors.description ? 'error' : null}
                            required>
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <InputArea
                                        autoGrow={true}
                                        rows={2}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        placeholder="Enter a test suite description"
                                    />
                                )}
                            />
                        </FormField>
                    </Cell>

                </Layout>
            </Box>
        )
    }



    if (!testSuite) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            screen="full"
        >
            <CustomModalLayout
                title={testSuite.id > 0 ? 'Edit Test Suite' : 'Create Test Suite'}
                subtitle={testSuite.description}
                primaryButtonText='Next'
                onCloseButtonClick={onClose}
                primaryButtonOnClick={handleNextClick}
                primaryButtonProps={{
                    disabled: savingStatus === 'saving' || (activeStep === 2 && (!ready))
                }}
                overflowY="none"
                removeContentPadding={true}
                width={800}
                sideActions={
                    <Button
                        onClick={onClose}
                        priority="secondary"
                        size="small"
                        disabled={savingStatus === 'saving' || (activeStep === 2)}
                    >
                        Cancel
                    </Button>
                }
            >
                <form onSubmit={handleSubmit(onSubmit)} ref={formRef} >
                    <Layout gap={6}>
                        <Cell span={12}>
                            <ComposerHeader
                                backButtonValue={activeStep === 1 ? 'Back' : undefined}
                                onBackClick={handleBackClick}
                            >
                                <ComposerHeader.Actions justifyContent="center">
                                    <Stepper
                                        type="circle"
                                        activeStep={activeStep}
                                        steps={[
                                            { text: 'Create' },
                                            { text: 'Select Type' },
                                            { text: 'Configure' },
                                        ]}
                                        onClick={(step) => setActiveStep(step)}
                                    />
                                </ComposerHeader.Actions>

                            </ComposerHeader>

                        </Cell>
                        <Cell>

                            {
                                activeStep === 0 && renderStepOne()
                            }
                            {
                                activeStep === 1 && (
                                    <Controller
                                        rules={{ required: true }}
                                        name="type"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <TestSuiteSelection
                                                value={value}
                                                onChange={(testSuiteType) => {
                                                    console.info('create test suite', testSuiteType);
                                                    onChange(testSuiteType)
                                                }
                                                }
                                            />
                                        )}
                                    />
                                )
                            }
                            {
                                activeStep === 2 && <RenderWaiter
                                    onComplete={onProgressComplete}
                                    status={savingStatus === 'saving' ? 'saving' : saveStatus !== '' ? 'error' : 'done'}
                                    message={saveStatus}

                                />

                            }


                        </Cell>
                    </Layout>

                </form>
            </CustomModalLayout>
        </Modal>

    );
};

export default TestSuiteEditModal;